<template>
  <div>
    <el-row :gutter="20" style=" margin-top: 20px; margin-bottom:20px">
    </el-row>
    <div class="page-header panel">
      <h3 class="title">
        Суртчилгааны хэрэгсэл
      </h3>
      <div class="action-section">
        <el-button
          type="success"
          size="mini"
          icon="el-icon-plus"
          @click="dialogVisibleAdd = true"
          :disabled="mArrayIncludes(role, ['other'])"
          >Нэмэх
        </el-button>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div align="right">
            <el-input
              style="width: 200px;"
              prefix-icon="el-icon-search"
              v-model="search"
              size="mini"
              placeholder="Хайх суртчилгааны нэр"
            />
          </div>
          <el-table size="mini" :data="kitchenBanner">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="imgUrl" label="Зураг" width="150px">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.imgUrl"
                  style="width: 80px;"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Гарчиг"> </el-table-column>
            <el-table-column prop="sort" label="Эрэмбэ" sortable>
            </el-table-column>
            <el-table-column
              :formatter="formatter"
              prop="startDate"
              label="Эхлэх огноо"
              sortable
            >
            </el-table-column>
            <el-table-column
              :formatter="formatterEndDate"
              prop="endDate"
              label="Дуусах огноо"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="isShow"
              label="Төлөв"
              width="100"
              :filters="[
                { text: 'Идэвхтэй', value: true },
                { text: 'Идэвхгүй', value: false }
              ]"
              :filter-method="filterTag"
              filter-placement="bottom-end"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isShow" size="medium" type="success"
                  ><i class="el-icon-success"></i
                ></el-tag>
                <el-tag v-else size="medium" type="danger"
                  ><i class="el-icon-error"></i
                ></el-tag>
                <!-- <el-tag v-if="scope.row.isShow == true" type="success">
                  Идэвхтэй
                </el-tag>
                <el-tag v-else-if="scope.row.isShow == false" type="info">
                  Идэвхгүй</el-tag
                > -->
              </template>
            </el-table-column>
            <el-table-column
              label="Үйлдэл"
              width="300px"
              v-if="!mArrayIncludes(role, ['other'])"
            >
              <template slot-scope="scope">
                <el-button
                  type="info"
                  size="mini"
                  icon="el-icon-edit"
                  @click="showEditKitchenBanner(scope.row)"
                  circle
                ></el-button>
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-delete-solid"
                  @click="deleteKitchenBanner(scope.row.id)"
                  circle
                ></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Суртчилгааны мэдээлэл засах"
      :visible.sync="dialogVisible"
      :before-close="closeProgressEdit"
    >
      <el-form :model="kitchenbannerEditData">
        <el-form-item label="Суртчилгааны нэр:">
          <el-input
            v-model="kitchenbannerEditData.name"
            size="mini"
            style="width:200px"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Суртчилгааны эрэмбэ:">
          <el-input-number
            v-model="kitchenbannerEditData.sort"
            size="mini"
            style="width:200px"
            autocomplete="off"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Cуртчилгааны төлөв" prop="status">
          <el-switch v-model="kitchenbannerEditData.isShow"> </el-switch>
        </el-form-item>
        <template>
          <div class="block">
            <span class="demonstration">Эхлэх огноо:</span>
            <el-date-picker
              style="margin-left:20px"
              size="mini"
              v-model="kitchenbannerEditData.startDate"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
          </div>
          <div class="block" style="margin-top:20px">
            <span class="demonstration">Дуусах огноо:</span>
            <el-date-picker
              style="margin-left:20px"
              size="mini"
              v-model="kitchenbannerEditData.endDate"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
          </div>
          <el-form-item>
            <span>Зураг</span>
            <base64-upload
              v-if="
                kitchenbannerEditData.imgUrl !== null &&
                  kitchenbannerEditData.imgUrl !== ''
              "
              style="width: 100px; height: 100px"
              :imageSrc="kitchenbannerEditData.imgUrl"
              @change="onChangeImageEdit"
            >
            </base64-upload>
            <a v-else v-loading="loadUpload">
              <base64-upload
                style="width:120px"
                :imageSrc="imageNull"
                @change="onChangeImageEdit"
              >
              </base64-upload>
            </a>
            <el-input
              size="mini"
              v-model="kitchenbannerEditData.imgUrl"
              disabled
            ></el-input>
          </el-form-item>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeProgressEdit">Буцах</el-button>
        <el-button size="mini" type="primary" @click="update()"
          >Засах</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Суртчилгааны мэдээлэл нэмэх"
      :visible.sync="dialogVisibleAdd"
      :before-close="closeProgress"
    >
      <el-form :model="kitchenbannerAddData">
        <el-form-item>
          <span>Зураг</span>
          <base64-upload
            v-if="
              kitchenbannerAddData.imgUrl !== null &&
                kitchenbannerAddData.imgUrl !== ''
            "
            style="width: 100px; height: 100px"
            :imageSrc="kitchenbannerAddData.imgUrl"
            @change="onChangeImage"
          >
          </base64-upload>
          <a v-else v-loading="loadUpload">
            <base64-upload
              style="width:120px"
              :imageSrc="imageNull"
              @change="onChangeImage"
            >
            </base64-upload>
          </a>
          <el-input
            size="mini"
            v-model="kitchenbannerAddData.imgUrl"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="Суртчилгааны нэр:">
          <el-input
            v-model="kitchenbannerAddData.name"
            size="mini"
            style="width:200px"
            autocomplete="off"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Суртчилгааны эрэмбэ:">
          <el-input-number
            v-model="kitchenbannerAddData.sort"
            size="mini"
            style="width:200px"
            autocomplete="off"
          ></el-input-number>
          <el-form-item label="Cуртчилгааны төлөв" prop="status">
            <el-switch v-model="kitchenbannerAddData.isShow"> </el-switch>
          </el-form-item>
          <!-- {{kitchenbannerAddData}} -->
        </el-form-item>
        <template>
          <div class="block">
            <span class="demonstration">Эхлэх огноо:</span>
            <el-date-picker
              style="margin-left:20px"
              size="mini"
              v-model="kitchenbannerAddData.startDate"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
          </div>
          <div class="block" style="margin-top:20px">
            <span class="demonstration">Дуусах огноо:</span>
            <el-date-picker
              style="margin-left:20px"
              size="mini"
              v-model="kitchenbannerAddData.endDate"
              type="date"
              placeholder="Pick a day"
            >
            </el-date-picker>
          </div>
        </template>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeProgress()">Буцах</el-button>
        <el-button size="mini" type="success" @click="save()">Нэмэх</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import Base64Upload from "vue-base64-upload";
import { getGroups } from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  components: {
    Base64Upload
  },
  mounted() {
    this.getBannerList();
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      statsPageCount: [
        {
          key: "home",
          doc_count: 0
        },
        {
          key: "restaurant",
          doc_count: 0
        }
      ],
      loadUpload: false,
      loadUploadEng: false,
      page: "",
      imageNull: "none",
      imageNullEng: "none",
      dialogVisible: false,
      search: "",
      kitchenBanner: [],
      kitchenbannerEditData: {},
      kitchenbannerAddData: {},
      dialogVisibleAdd: false,
      pagePassProps: "",
      sendEdit: false,
      role: []
    };
  },
  methods: {
    formatter(row) {
      var convertDate = row.startDate.substring(-6, 10);
      return convertDate;
    },
    formatterEndDate(row) {
      var convertDate = row.endDate.substring(-6, 10);
      return convertDate;
    },
    closeProgress() {
      this.kitchenbannerAddData = {};
      this.kitchenbannerAddData.imgUrl = "";
      this.dialogVisibleAdd = false;
      this.getBannerList();
    },
    closeProgressEdit() {
      this.kitchenbannerEditData = {};
      this.kitchenbannerEditData.imgUrl = "";
      this.dialogVisible = false;
    },
    showEditKitchenBanner(data) {
      this.kitchenbannerEditData = data;
      this.dialogVisible = true;
    },
    onChangeImage(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 10MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
      } else {
        if (!file.name) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          let payload = {};
          payload.file = file.base64;
          payload.bannerName = file.name;
          service.kitchenUploadBanner(payload).then(res => {
            this.kitchenbannerAddData.imgUrl = res.img_url;
            this.loadUpload = false;
          });
        }
      }
      return isLt2M;
    },
    onChangeImageEdit(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 10MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
      } else {
        if (!file.name) {
          this.$message.error(
            "Зурагны нэртэй холбоотой алдаа гарлаа хөгжүүлэгчтэй холбогдоно уу!"
          );
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          let payload = {};
          payload.file = file.base64;
          payload.bannerName = file.name;
          service.kitchenUploadBanner(payload).then(res => {
            this.kitchenbannerEditData.imgUrl = res.img_url;
            this.loadUpload = false;
          });
        }
      }
      return isLt2M;
    },
    save() {
      service.createKitchenBanner(this.kitchenbannerAddData).then(response => {
        if (response) {
          this.$notify({
            title: "Амжилттай",
            message: "Суртчилгааны мэдээлэл нэмэгдлээ",
            type: "success"
          });
          this.closeProgress();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Суртчилгааны мэдээлэл нэмэхэд алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    getOutletById(id) {
      this.$router.push({
        path: "edit-banner",
        query: {
          id: id
        }
      });
      this.$notify({
        title: "Амжилттай",
        message: id + " " + "мэдээллийг засах",
        type: "success"
      });
    },
    filterTag(value, row) {
      return row.isShow === value;
    },
    getBannerList() {
      service.getKitchenBanner().then(res => {
        this.kitchenBanner = res;
      });
    },
    filterTag1(value) {
      this.page = value;
    },
    update() {
      service.updateKitchenBanner(this.kitchenbannerEditData).then(response => {
        if (response) {
          this.$notify({
            title: "Амжилттай",
            message: "Суртчилгааны мэдээлэл засагдлаа",
            type: "success"
          });
          this.closeProgressEdit();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Суртчилгааны мэдээлэл засахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
        }
      });
    },
    deleteKitchenBanner(data) {
      var sendData = {
        id: data
      };
      service.deleteKitchenBanner(sendData).then(response => {
        if (response) {
          this.$notify({
            title: "Амжилттай",
            message: "Суртчилгааны мэдээлэл устгагдлаа",
            type: "success"
          });
          this.getBannerList();
        } else if (response.status === "unsuccess") {
          this.$notify({
            title: "Амжилтгүй",
            message:
              "Суртчилгааны мэдээлэл устгахад алдаа гарлаа " +
              " " +
              response.error.message,
            type: "warning"
          });
          this.getBannerList();
        }
      });
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    }
  }
};
</script>
